
<!--设备运维：故障报修-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
        <h2 class="h2Style">
          故障报修
        </h2>
        <!--搜索栏-->
        <div class="control-box1">
          <div class="cb-left">
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addTeamUserShow()"
            >
              添加
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="teamUser"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column label="设备名称" align="center" width="300">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="报修类型" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.type }}</span>
              </template>
            </el-table-column>
            <el-table-column label="维修人员" align="center" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.person }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="提报时间" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="状态" align="center" width="150">
              <template slot-scope="scope">
                <span style="font-weight: bold;" :style="{color: scope.row.result === '通过' ? 'green' : 'grey' }">
                {{scope.row.result}}
              </span>
              </template>
            </el-table-column>
            <!--操作-->
            <el-table-column label="操作" align="center" width="170">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="updateTeamUser(scope.row)"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加异常提报-->
      <el-dialog
          :visible.sync="TeamUserShow"
          title="添加故障报修"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
          width="30%"
          @close="close1()"
      >
        <el-form label-position="left" ref="inviteForm" label-width="110px" :model="invite" :rules="rules.inviteRules">
          <el-form-item label="设备名称" prop="deviceId">
            <el-select
                v-model="invite.deviceId"
                placeholder="请选择设备"
                clearable
                style="width: 100%"
            >
              <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="故障类型" prop="project">
            <el-select
                v-model="invite.project"
                placeholder="请选择更换项目"
                style="width: 100%"
                clearable
            >
              <el-option
                  v-for="item in repairTypes"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="维修人员" class="person" prop="">
            <span class="btn" v-if="!invite.name" @click="handlePerson">非必选<i class="el-icon-arrow-right"></i></span>
            <span class="btn" v-else style="color:#606266" @click="handlePerson">{{ invite.name }}<i class="el-icon-arrow-right"></i></span>
          </el-form-item>
          <el-form-item label="" v-if="invite.deviceId" class="record" prop="">
           <span>历史维修记录</span>
          </el-form-item>
          <el-form-item label="故障问题" prop="repairInfo">
            <el-input
                v-model="invite.repairInfo"
                show-word-limit
                clearable
                type="textarea"
                :rows="4"
                placeholder="请输入故障问题 （最多150字）"
                maxlength="150"
            />
          </el-form-item>
          <div class="pic-and-video-wrapper">
            <div v-for="(img, index) in pics" :key="index" class="preview">
              <el-image
                  style="width: 100%"
                  :src="img"
                  :preview-src-list="pics">
              </el-image>
              <van-button class="van-btton" icon="delete" type="primary" size="mini" @click="removeImage(index)" />
            </div>
            <div class="btn" @click="selectImage" v-show="pics.length < 3">
              <img src="@/assets/img/add-pic.png" alt="add pic" />
              <span style="font-size: 12px;">添加照片</span>
              <input v-show="false" ref="imgInput" type="file" accept="image/*" onclick="event.cancelBubble = true"
                     multiple @input="pickImage" />
            </div>
            <div class="btn" v-if="!!video">
              <span @click="playVideo">点击播放</span>
              <div class="closeVideo-btn" @click="removeVideo">
                <img src="@/assets/img/img_2.png" />
              </div>
            </div>
            <div class="btn" @click="selectVideo" v-show="!video">
              <img src="@/assets/img/add-video.png" alt="add video" />
              <span style="font-size: 12px;">添加视频</span>
              <input v-show="false" ref="videoInput" type="file" accept="video/*" onclick="event.cancelBubble = true"
                     multiple @input="pickVideo" />
            </div>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="TeamUserShow = false">取 消</el-button>
          <el-button type="primary" :loading="addUserBtnLoading" @click="addTeamUser()">
            提交
          </el-button>
        </div>
      </el-dialog>
      <!-- 播放弹出框 -->
      <el-dialog :visible.sync="showVideo"
                 :close-on-click-modal="false"
                 :modal-append-to-body="false"
                 :append-to-body="false"
                 @close="close2()">
        <video ref="videoShow" style="width:100%;height:100%" controls>
          <source :src="videoSrc" type="video/mp4">。
        </video>
      </el-dialog>
      <el-drawer
          title="人员选择"
          :visible.sync="drawer"
          :direction="direction"
          :before-close="handleClose">
        <span>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="内部" name="first">
              <div v-for="(item,index) in teamList" :key="index">
                <div v-if="item.orderType === '1'" class="item" >
                  <div style="display: flex; align-items: center;">
<!--                    <img src="@/assets/img/icon-pen.png" alt="">-->
                    <span>{{ item.cName }}</span>
                  </div>
                  <div>
                    <el-radio v-model="item.phone" label="" @input="handleRadio(item)"></el-radio>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="外部" name="second">
              <div v-for="(item,index) in teamList" :key="index">
                <div v-if="item.orderType === '2'"  class="item">
                  <div>
<!--                    <img src="" alt="">-->
                    <span>{{ item.cName }}</span>
                  </div>
                  <div>
                   <el-radio v-model="item.phone" label="" @input="handleRadio(item)"></el-radio>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </span>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import API from '@/api';
import valid from '@/utils/validator';
import { base64toFile } from '@/utils/file';
import { manageOauth } from '@/utils/validate';
import moment from "moment";
export default {
  name: 'faultRepair',
  data() {
    return {
      activeName: 'first',
      teamUser: [],
      TeamUserShow: false,
      addUserBtnLoading: false,
      invite: {
        deviceId: '',// 设备
        repairInfo: '',// 问题
        orderType: 1, //
        project: '',
        userId: '', // 维修人员id
        name: '', // 维修人员名称
      },
      pics: [],
      video: '',
      voice: null,
      showVideo: false,
      videoSrc: null,
      repairTypes: ['电气', '机械', '调试', '其它'],
      pickerOptions: {
        selectableRange: '00:00:00-23:59:59' // 限制可选择的时间范围
      },
      deviceList:[],
      reasonList: [],
      listLoading: false,
      drawer:false,
      direction: 'rtl',
      // 查询
      form: {
        page: 1,
        count: 10,
        type: 3
      },
      rules: {
        inviteRules: {
          deviceId: [{ required: true, message: '请选择设备', trigger: 'change' }],
          project: [{ required: true, message: '请选择更换目录', trigger: 'change' }],
          repairInfo: [{ required: true, trigger: 'blur', message: '请输入故障问题' }],
        },
      },
      totalCount: 0,
      teamList: []
    };
  },
  created() {
    this.getTeamUser();
    this.getList()
  },
  mounted() {

  },
  methods: {
    getTeamUser() {
      API.getRecordList(this.form).then((response) => {
        this.teamUser = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    getList() {
      API.repairDeviceList().then(res => {
        this.deviceList.push(...res.message.data)
      })
      API.reasonList({type:14}).then((res) => {
        this.reasonList.push(...res.message.data)
      })
      this.teamList = []
      API.teamList({type:3}).then(res => {
        this.teamList.push(...res.message.data)
      })
    },
    removeImage (index) {
      this.pics.splice(index, 1);
    },
    pickImage () {
      let input = this.$refs.imgInput
      if (input.files.length + this.pics.length > 3) {
        message('warning','最多上传3张照片')
        return;
      }
      console.log(input.files[0])
      for (let i = 0; i < input.files.length; i++) {
        console.log(input.files[i])
        if(input.files[i]) {
          console.log(4544)
          //在此限制图片的大小
          let imgSize = input.files[i].size;
          //35160  计算机存储数据最为常用的单位是字节(B)
          //在此处我们限制图片大小为2M
          if(imgSize>50*1024*1024){
            message('warning',"上传的图片大于50M,请重新选择")
            return;
          }
          let reader = new FileReader
          reader.onload = e => {
            this.pics.push(e.target.result)
          }
          reader.readAsDataURL(input.files[i])
        }
      }
      this.$refs.imgInput.value = null;
    },
    selectVideo () {
      this.$refs.videoInput.click()
    },
    removeVideo () {
      this.video = null;
      this.videoSrc = null;
    },
    //播放视频
    playVideo () {
      this.showVideo = true;
    },
    selectImage () {
      this.$refs.imgInput.click()
    },
    pickVideo () {
      let input = this.$refs.videoInput
      console.log(input.files)
      if (input.files.length > 0) {
        //在此限制图片的大小
        let videoSize = input.files[0].size;
        //35160  计算机存储数据最为常用的单位是字节(B)
        //在此处我们限制图片大小为2M
        if(videoSize>50*1024*1024){
          message('warning',"上传的视频大于50M,请重新选择")
          return;
        }
        this.video = null
        this.videoSrc = null
        let reader = new FileReader
        reader.onload = e => {
          this.video = e.target.result;
          this.videoSrc = e.target.result;
        }
        reader.readAsDataURL(input.files[0])
      }
      this.$refs.videoInput.value = null
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handlePerson() {
      this.teamList = []
      API.teamList({type:3}).then(res => {
        this.teamList.push(...res.message.data)
      })
      this.drawer = true
    },
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    // 查看
    updateTeamUser() {

    },
    handleRadio(item) {
      console.log(item)
      this.invite.userId = item.id
      this.invite.name = item.cName
      this.drawer = false
    },
    addTeamUserShow() {
      this.invite = {
        deviceId: '',// 设备
        repairInfo: '',// 问题
        orderType: 1, //
        project: '',
        userId: '', // 维修人员id
        name: '', // 维修人员名称
      }
      this.pics=[]
      this.video= ''
      this.voice= null
      this.showVideo= false
      this.videoSrc= null
      this.TeamUserShow = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.inviteForm.clearValidate();
      });
    },
    close1() {
      this.TeamUserShow = false;
    },
    close2() {
      this.showVideo = false;
    },
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          this.addUserBtnLoading = true;
          const formdata = new FormData();
          formdata.append('repairType', this.invite.project)
          formdata.append('orderType', "1")
          console.log(this.invite.userId)
          if (this.invite.userId === undefined) {
            this.invite.userId = ''
            formdata.append('userId', this.invite.userId)
          } else {
            formdata.append('userId', this.invite.userId)
          }

          formdata.append('deviceId', this.invite.deviceId)
          formdata.append('repairInfo', this.invite.repairInfo)
          this.pics.forEach((pic, index) => {
            formdata.append(`pic${index + 1}`, base64toFile(pic))
          });
          if (this.video) {
            formdata.append('vid', base64toFile(this.video));
          }
          API.submitBaoxiu(formdata)
              .then((response) => {
                // console.log(response.message.success)
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.TeamUserShow = false;
                  this.getTeamUser();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  // this.$message({
                  //   message: response.message.message,
                  //   // showClose: true,
                  //   type: 'error',
                  // });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTeamUser();
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
.btn {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  width: 100%;
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  padding: 12px 10px 0px 15px;
  color: #cccfd6;
  cursor: pointer;
}
.person {
  margin-bottom: 20px;
}
.el-icon-arrow-right {

}
::v-deep .el-drawer__body {
  padding: 0 20px;
}
::v-deep .el-drawer__header {
  font-size: 18px;
  color: #3D3D3D;
}
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  span {
    display: inline-block;
  }
  img {
    width: 32px;
    margin-right: 10px;
  }
}
.record {
  margin-bottom: 0px;
  span{
    display: inline-block;
    text-align: right;
    width: 100%;
cursor: pointer;
  }
}
.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .btn {
    width: 90px;
    height: 90px;
    margin-top: 10px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 0;
padding: 0;
    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 5px;
      font-size: 13px;
      color: grey;
    }
    .closeVideo-btn {
      margin-top: 8px;
    }
  }
}

.preview {
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;

  img {
    position: absolute;
    width: 90px;
    height: 90px;
  }

  .van-btton {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 60px;
  }
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 22px;
    height: 22px;
  }
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

  .close-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
